<template>
    <img v-if="src" :width="width" :height="height" :src="src" />
</template>

<script>
import { $themeConfig } from "@themeConfig"

export default {
    name: "LogoComponent",
    data() {
        return {
            width: "",
            height: "",
            src: "",
        }
    },
    created() {
        this.width = $themeConfig.app.appLogoSize.width
        this.height = $themeConfig.app.appLogoSize.height
        this.src = $themeConfig.app.appLogoImage
    },
}
</script>
